<!-- 营销中心 平台营销工具 平台卡券管理 优惠券管理 优惠券详情 -->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons.filter(item => item.enabled())"
          :key="index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="content">
      <ykc-detail-plus :labelWidth="90">
        <div slot="header" class="clearfix">
          <span class="title">优惠券信息</span>
          <span class="title-flag" v-if="detail.cancelFlag === '1'">已作废</span>
        </div>
        <ykc-detail-item-plus label="优惠券名称">
          <span>{{ dealData(detail.cardCouponName) }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="优惠券类型">
          <span v-if="detail.useType === '1'">普通券</span>
          <span v-else-if="detail.useType === '2'">会员券</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="优惠券面额">
          <span>
            {{ getDeductionDic(detail.deductionType) }}满{{ dealData(detail.amountLimit) }}减{{
              dealData(detail.faceValue)
            }}元
          </span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="优惠券数量">
          <span>{{ detail.useType === '2' ? '——' : dealData(detail.stockNum) }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="修改人">
          <span>{{ dealData(detail.operatorBy) }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="创建时间">
          <span>{{ dealData(detail.createdTime) }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="修改时间">
          <span>{{ dealData(detail.modifiedTime) }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="有效使用时间">
          <span v-if="detail.effectiveTimeType === '1'">
            领取后{{ dealData(detail.afterReceiveDay) }}天
          </span>
          <span v-if="detail.effectiveTimeType === '2'">
            {{ dealData(detail.effectiveStartDate) }} ~ {{ dealData(detail.effectiveEndDate) }}
          </span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="使用说明" style="word-break: break-all">
          <span>{{ detail.useInstructions || '无' }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="可用站点维度">
          <span>{{ scopeTypeFormat(detail.scopeType) }}</span>
        </ykc-detail-item-plus>
      </ykc-detail-plus>
      <ykc-table
        v-if="['1', '2', '3'].includes(detail.scopeType)"
        style="margin-top: 16px"
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        :title="tableTitle"
        :operateButtons="tableOperateButtons">
        <template #headerButtons>
          <ykc-button
            type="plain"
            v-rbac="'marketing:platform:coupon:detaildownlist'"
            @click="doExportFile2">
            导出
          </ykc-button>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="total"
          :current-page.sync="currentPage"
          @current-change="handlePaginationCurrentChange"
          :page-size.sync="pageSize"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { marketManage } from '@/service/apis';
  import { offlineExport } from '@/utils';

  export default {
    name: 'platformCouponDetail',
    data() {
      return {
        tableData: [],

        tableColumns: [
          { label: '电站编码', prop: 'stationId' },
          { label: '电站名称', prop: 'stationName' },
          { label: '电站类型', prop: 'stationType' },
          { label: '归属商户', prop: 'merchantName' },
        ],
        tableTitle: '优惠券可用站点',

        tableOperateButtons: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageButtons: [
          {
            enabled: () => true,
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        detail: {},
        cardCouponId: '',
      };
    },
    computed: {},
    created() {
      const query = JSON.parse(this.$route.query.data);
      this.cardCouponId = query.cardCouponId;
      this.getDetail();
      this.getListData();
    },
    methods: {
      scopeTypeFormat(val) {
        const scopeTypeMap = {
          1: '按城市',
          2: '按商户',
          3: '按分组',
          4: '全部电站',
        };
        return scopeTypeMap[val];
      },
      /**
       * 获取详情数据
       */
      getDetail() {
        marketManage
          .detail({ cardCouponId: this.cardCouponId, undertakerType: '1', cardCouponType: '2' })
          .then(res => {
            console.log(res);
            this.detail = res || {};
          });
      },
      dealData(data) {
        return data || '——';
      },
      getDeductionDic(id) {
        const deduction_type = {
          1: '电费',
          2: '服务费',
          3: '总费用',
        };
        return deduction_type[id] || '——';
      },
      getListData() {
        marketManage
          .stationPage({
            cardCouponId: this.cardCouponId,
            undertakerType: '1',
            cardCouponType: '2',
            current: this.currentPage,
            size: this.pageSize,
          })
          .then(res => {
            console.log(res);
            this.tableData = res?.records || [];
            this.total = res?.total;
          })
          .catch(() => {
            this.tableData = [];
            this.total = 0;
          });
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.currentPage = current;
        this.getListData();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.currentPage = 1;
        this.pageSize = size;
        this.getListData();
      },
      // 导出 table
      doExportFile2() {
        console.log('doExportFile2');
        offlineExport(
          {
            downloadType: 'marketing_export',
            jsonNode: {
              downloadKey: 'platform_coupon_station_export',
              cardCouponId: this.cardCouponId,
              cardCouponType: '2',
              undertakerType: '1',
            },
          },
          this.total
        );
      },
    },
  };
</script>
<style lang="scss" scoped>
  .content {
    // display: flex;
    .clearfix {
      height: 19px;
      display: flex;
      align-items: center;
    }
    .box-card {
      margin: 0;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .status {
        margin-left: 10px;
        color: #fff;
        height: 20px;
        border-radius: 10px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        line-height: 20px;
        &.stationStatus {
          &.status1000135 {
            background-color: #009f09;
          }
          &.status1000136 {
            background-color: #e81b1b;
          }
        }
        &.infoStatus {
          &.status1 {
            background-color: #009f09;
          }
          &.status2 {
            background-color: #ccc;
            color: #fff;
          }
          &.status0 {
            background-color: #e81b1b;
          }
        }
      }
      ::v-deep .el-form-item {
        display: flex;
        align-items: center;
      }
      table {
        width: calc(100% - 30px);
        border-left: 1px solid #ddd;
        border-top: 1px solid #ddd;
        margin-left: 30px;
        margin-top: 20px;
        th {
          background-color: #f6f7f8;
          font-weight: bold;
        }
        th,
        td {
          font-size: 12px;
          text-align: left;
          padding: 0 10px;
          line-height: 40px;
          height: 40px;
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          ::v-deep .el-form-item {
            margin-bottom: 0;
            input {
              width: 100px;
            }
          }
        }
      }
      .title {
        position: relative;
        &-flag {
          margin-left: 8px;
          padding: 2px 18px;
          background: rgba(77, 140, 253, 0.1);
          border-radius: 2px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #4d8cfd;
          line-height: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
</style>
